//
//
//  Preview
//
//

import classes from "./PreviewProject.module.css";


function PreviewProject({img}: {img: string}) {
    return (
        <div className={classes.window}>
            <div className={classes.toolbar}>
                <div className={classes.redIcon}></div>
                <div className={classes.orangeIcon}></div>
                <div className={classes.greenIcon}></div>
            </div>
            <div className={classes.content}>
                <img className={classes.contentImage} src={img} alt="Content"/>
            </div>
        </div>
    )
}

export default PreviewProject
