import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from "@sentry/react"
import App from './App.tsx'
import './index.css'
import { MemoryRouter } from 'react-router-dom'

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
})

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <MemoryRouter>
            <App />
        </MemoryRouter>
    </React.StrictMode>,
)
