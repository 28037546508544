//
//
//  Data
//
//

import maiaCognitiveImg from "../assets/maia-cognitive.png";
import maiaDashboardImg from "../assets/maia-dashboard.png";
import alertManagementImg from "../assets/alert-management.png";
import maiaVideoChatImg from "../assets/maia-videochat.png";
import maiaSabadellImg from "../assets/maia-sabadell.png";


export default [
    {
        title: "MAIA Cognitive",
        description: "...",
        image: maiaCognitiveImg,
        dataSections: [
            {
                "section": "",
                "items": [
                    {
                        "name": "Website",
                        "value": "https://www.maiacognitive.com"
                    }
                ]
            }
        ]
    },
    {
        title: "MAIA Dashboard",
        description: "...",
        image: maiaDashboardImg,
        dataSections: [
            {
                "section": "",
                "items": [
                    {
                        "name": "Website",
                        "value": "https://maiacognitive.maia-cognitive.com"
                    }
                ]
            }
        ]
    },
    {
        title: "Alert Management",
        description: "...",
        image: alertManagementImg,
        dataSections: [
            {
                "section": "",
                "items": [
                    {
                        "name": "Website",
                        "value": "https://alert.maiadashboard.com/"
                    },
                    {
                        "name": "Repository",
                        "value": "https://github.com/MAJOREL-IBILAT/alert_management"
                    }
                ]
            }
        ]
    },
    {
        title: "MAIA VideoChat",
        description: "Interact with MAIA using video chat",
        image: maiaVideoChatImg,
        dataSections: [
            {
                "section": "",
                "items": [
                    {
                        "name": "Website",
                        "value": "https://majorel-ibilat.github.io/maia-videochat/"
                    },
                    {
                        "name": "Repository",
                        "value": "https://github.com/MAJOREL-IBILAT/maia-videochat"
                    }
                ]
            }
        ]
    },
    {
        title: "MAIA Sabadell",
        description: "MAIA Sabadell is a chatbot that allows you to manage your insurance account, integrating with Genesys, allowing redirection to specific work queues for each service",
        image: maiaSabadellImg,
        dataSections: [
            {
                "section": "",
                "items": [
                    {
                        "name": "Phone",
                        "value": "911 67 55 15"
                    }
                ],
            },
            {
                "section": "",
                "items": [
                    {
                        "name": "Website - Chatbot (Spanish)",
                        "value": "https://streamlit-3wjxu2ndtq-no.a.run.app"
                    },
                    {
                        "name": "Website - Chatbot (Catalan)",
                        "value": "https://ca-streamlit-3wjxu2ndtq-no.a.run.app"
                    },
                    {
                        "name": "Website - Chatbot (English)",
                        "value": "https://en-streamlit-3wjxu2ndtq-no.a.run.app"
                    }
                ]
            },
            {
                "section": "",
                "items":[
                    {
                        "name": "Languages by Phone",
                        "value": "Default - Spanish\nEnglish - 695724230, 607502245\nCatalan - 645029070"
                    },
                    {
                        "name": "DNI",
                        "value": "12345671 - Multiple policies, all of type NO BLINK\n12345672 - One policy of type NO BLINK\n12345673 - Multiple policies, some of type BLINK and others of type NO BLINK"
                    }
                ]
            },
            {
                "section": "",
                "items": [
                    {
                        "name": "Repository",
                        "value": "https://github.com/MAJOREL-IBILAT/maia-sabadell-poc"
                    }
                ]
            }
        ]
    }
]
