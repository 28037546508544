//
//
//
//
//

import {Divider} from "@tremor/react";

function Project({project}: {project: any}) {
    return (
        <>
            <div className="flex flex-col gap-2">
                <h3 className="text-xl font-bold text-black">{project.title}</h3>
                <p className="text-md">{project.description}</p>
            </div>
            <Divider className="my-4"/>
            <div className="flex flex-col">
                {project.dataSections.map((dataSection: any, dataSectionIndex: number) => {
                    return (
                        <div className="flex flex-col gap-2" key={"section-" + dataSectionIndex}>
                            {dataSection.section && <h4 className="text-md font-bold text-black">{dataSection.section}</h4>}
                            {dataSection.items.map((item: any, itemIndex: number) => {
                                let value
                                if (typeof item.value === "string" && item.value.startsWith("http")) {
                                    value = <a className="text-blue-600" href={item.value} target="_blank">{item.value}</a>
                                } else if (typeof item.value === "string") {
                                    value = <span className="whitespace-pre-wrap">{item.value}</span>
                                } else {
                                    value = item.value
                                }

                                return (
                                    <div className="flex flex-row gap-2" key={"item-" + itemIndex}>
                                        <span className="font-bold whitespace-pre-wrap">{item.name}</span>
                                        {value}
                                    </div>
                                )
                            })}
                            {dataSectionIndex < project.dataSections.length - 1 && <Divider/>}
                        </div>
                    )
                })}
            </div>
    </>)
}

export default Project
