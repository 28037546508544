import { ReactNode } from 'react';

interface LogoIconProps {
  className?: string;
  children: ReactNode;
}

function LogoIcon({ children, className }: LogoIconProps) {
  return (
    <span className={`${className} inline-block transition-transform duration-300 transform hover:scale-110`}>
      {children}
    </span>
  );
}

export default LogoIcon;

