//
//
//  Projects
//
//

import {Button, Col, Dialog, DialogPanel, Grid} from "@tremor/react";
import OtherProjectsData from "./data/other.tsx";
import PreviewProject from "./components/PreviewProject.tsx";
import Project from "./components/Project.tsx";
import {useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {RiLogoutBoxRLine} from "@remixicon/react";

function OtherProjects() {
    const { logout } = useAuth0();
    const navigate = useNavigate();
    const [projectIndexOpened, setProjectIndexOpened] = useState<number>(-1)

    return (
        <>
            <div className="container mx-auto mb-10">
                <div className="flex justify-between mb-12 mt-14">
                    <div className="flex">
                        <Button size="xs" variant="primary" onClick={() => navigate('/')}>Go back</Button>
                    </div>
                    <h1 className="text-3xl font-bold">Other Projects</h1>
                    <div className="flex">
                        <Button size="xs" variant="secondary" icon={RiLogoutBoxRLine} iconPosition="right" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Logout</Button>
                    </div>
                </div>

                <Grid numItems={1} numItemsSm={2} numItemsLg={3} className="gap-10">
                    {OtherProjectsData.map((project, index) => {
                        return (
                            <Col numColSpan={1} key={index}>
                                <button className="w-full h-full" onClick={() => setProjectIndexOpened(index)}>
                                    <h2 className="text-lg font-semibold mb-2">{project.title}</h2>
                                    <PreviewProject
                                        img={project.image}
                                    />
                                </button>
                            </Col>
                        )
                    })}
                </Grid>
            </div>
            <Dialog open={projectIndexOpened >= 0} onClose={() => setProjectIndexOpened(-1)} static={true}>
                {projectIndexOpened >= 0 && <DialogPanel className="max-w-fit">
                    <Project project={OtherProjectsData[projectIndexOpened]}/>
                </DialogPanel>}
            </Dialog>
        </>
    )
}

export default OtherProjects
