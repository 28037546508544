//
//
//  Require Logged In
//
//

import {Outlet} from "react-router-dom"
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";



 function RequireLoggedIn() {
     const { isLoading} = useAuth0()

     if (isLoading) {
         return (
           <div>Loading...</div>
         )
     }

     const Component = withAuthenticationRequired(Outlet, {
         returnTo: () => window.location.hash.substring(1),
         onRedirecting: () => {
             return (
                <div>Loading...</div>
             )
         }
     })
     return <Component/>
 }

export default RequireLoggedIn
