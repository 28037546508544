//
//
//  App
//
//

import {Auth0Provider} from "@auth0/auth0-react";
import Projects from "./Projects.tsx";
import {Route, Routes} from "react-router-dom";
import OtherProjects from "./OtherProjects.tsx";
import RequireLoggedIn from "./components/RequireLoggedIn.tsx";


function App() {

    return (
        <Auth0Provider
             domain={import.meta.env.VITE_AUTH0_DOMAIN}
             clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
             authorizationParams={{
                 redirect_uri: window.location.origin,
             }}
             cacheLocation="localstorage"
        >
            <Routes>
                <Route element={<RequireLoggedIn/>}>
                    <Route path="/" Component={Projects}/>
                    <Route path="/other" Component={OtherProjects}/>
                </Route>
            </Routes>
        </Auth0Provider>
    )
}

export default App
